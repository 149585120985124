<template>
  <div class="row align-items-stretch">
    <div
      v-for="(media, index) in medias"
      :key="index++"
      class="col-xl-3 col-12 py-xl-3"
    >
      <div class="card shadow border-0 p-0 h-100">
        <div class="img-card-top h-65">
          <img :src="media.image" class="w-100 rounded-top h-100" />
        </div>
        <div class="card-body">
          <br />
          <div class="col">
            {{ media.title }}
          </div>
          <div class="col">
            {{ media.publicationDate }}
          </div>

          <div class="d-flex justify-content-end">
            <button class="btn btn-outline-danger">Supprimer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  beforeCreate() {
    this.$store.dispatch("media/fetch");
  },
  computed: {
    ...mapGetters("media", { medias: "getMedias" }),
  },
};
</script>
